//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'RegisterS',
  data() {
    return {
      type: this.$route.query.type
    }
  },
  methods: {
    backIndex () {
      this.$router.push('/')
    },
    back() {
      this.$router.back(-1)
    }
  }
}
